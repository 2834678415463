
.sidebar {
    color: #FFF;
    background: #22B1DF;
    width: 250px;
    max-width: 250px;
    height: 100%;
    float: left;
    position: fixed;
    z-index: 1000;
    display: block;
    -webkit-transition: margin 2s;
    transition: margin 2s;
  flex:1;
}
#navbar-toggle {
    cursor: pointer;
}
#toggleView {
    margin-left: 44px;
}
.menu-icon {
    float: right;
}
.sidebar-nav {
    display: block;
    float: left;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}
.sidebar-nav li a {
    padding-left: 20px;
    font-size: 16px;
    text-decoration: none;
    color: #FFF;
    float: left;
    text-decoration: none;
    width: 100%;
    height: 70px;
    line-height: 25px;
    padding: 20px;
    vertical-align: center;
}
.sidebar-nav li a:hover {
    background:#51c2e5;
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
}
/* Sidebar.css or another CSS file */
.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    text-align: center;
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  