.dashboard-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* 2 cards per row */
    gap: 20px; /* Space between cards */
    padding: 20px; /* Padding around the grid */
    margin-left: 5pc;
    -webkit-transition: margin 2s;
    transition: margin 2s;
  }
  
  .card {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9; /* Light background for cards */
    transition: transform 0.3s ease; /* Smooth scale effect on hover */
    cursor: pointer;
  }
  
  .card:hover {
    transform: scale(1.05); /* Scale up card on hover */
  }
  
  .quotes-card {
    background-color: #e0f7fa; /* Light blue background for quotes card */
  }
  
  .contact-card {
    background-color: #fff3e0; /* Light orange background for contact card */
  }
  
  .distribution-card {
    background-color: #e8f5e9; /* Light green background for distribution card */
  }
  
  .subscription-card {
    background-color: #f3e5f5; /* Light purple background for subscription card */
  }
  .dashboard-container.open{
    margin-left: 15pc;
    -webkit-transition: margin 2s;
    transition: margin 2s;
  }
  @media screen and (max-width:767px) {
    .dashboard-container.open{
        margin-left: 5pc;
    }
  }
  @media screen and (max-width:374px) {
    .quotes-card,.contact-card,.distribution-card,.subscription-card{
        width: 200px;
    }
  }