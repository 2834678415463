/* Container settings */
.three-steps-container {
  background: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px -1px rgba(0, 0, 0, 0.1);
}

/* Flip card styles */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flip-card-front {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flip-card-back {
  background: #22B1DF;;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step-title {
  font-weight: bold;
}

.flip-image {
  max-height: 200px;
  max-width: 100%;
  border-radius: 8px;
}

.hydration-p-heading{
  color: #22B1DF;
  font-weight: 300;
  word-spacing: 3px;
  letter-spacing: 1px;
  line-height: 30px;
}