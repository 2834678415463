/* Container */
.uploadcan-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 300px;
  transition: margin-left 0.3s ease;
  -webkit-transition: margin 2s;
  transition: margin 2s;
}

.uploadcan-container.openin {
  transition: margin-left 0.3s ease;
  -webkit-transition: margin 2s;
  transition: margin 2s;
}

.uploadcan-container.close {
  margin-left: 50px;
  transition: margin-left 0.3s ease;
  -webkit-transition: margin 2s;
  transition: margin 2s;
}

.uploadcan-heading {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #333;
  position: relative;
}

.uploadcan-instructions{
  text-align: center;
}


/* View Cans Button */
.uploadcan-view-btn {
  position: absolute;
  right: 20px;
  top: 0;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 30px;
}

.uploadcan-view-btn:hover {
  background-color: #218838;
}

/* Form Styling */
.uploadcan-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.uploadcan-form-group {
  display: flex;
  flex-direction: column;
}

.uploadcan-label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #555;
}

.uploadcan-input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.uploadcan-submit {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.uploadcan-submit:hover {
  background-color: #0056b3;
}

/* Image Container for Can Preview */
.uploadcan-img-container {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust this to represent the can's height */
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Image Styling for Proper Containment */
.uploadcan-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ensures the image fits within the can body */
  object-position: center;
}

/* Message Styling */
.uploadcan-message {
  text-align: center;
  font-size: 14px;
  color: #28a745;
  margin-top: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .uploadcan-container {
    padding: 15px;
    max-width: 100%;
  }

  .uploadcan-heading {
    font-size: 20px;
  }

  .uploadcan-view-btn {
    position: static;
    display: block;
    margin: 10px auto;
    width: fit-content;
  }

  .uploadcan-form {
    padding: 15px;
  }

  .uploadcan-submit {
    padding: 10px;
    font-size: 14px;
  }

  .uploadcan-input {
    padding: 6px;
    font-size: 13px;
  }

  .uploadcan-img-container {
    height: 250px; /* Reduced height for mobile view */
  }
}
