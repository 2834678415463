/* Container styles */
.contactget-container {
    /* padding: 20px;
    margin: 0 auto;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: 5pc;
    -webkit-transition: margin 2s;
    transition: margin 2s; */
    padding: 10px;
    margin-left: 200px; /* Adjust if needed */
    transition: margin-left 0.3s ease;
    -webkit-transition: margin 2s;
    transition: margin 2s;
    
  }
.open{
    margin-left: 17pc;
  }
  @media screen and (max-width: 768px) {
    .open {
      margin-left: 4pc; /* Adjust if needed */
    }
    .close {
      margin-left: 4pc; /* Adjust if needed */
    }
}
.close {
  margin-left: 4pc; /* Adjust if needed */
}
  
  
  /* Heading styles */
  .contactget-heading {
    /* text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px; */
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  .contactget-table-wrapper {
    overflow-x: auto;
    margin: 0 auto;
  }
  /* Table styles */
  .contactget-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .contactget-table-header-row {
    background-color: #22B1DF;
  }
  
  .contactget-table-header {
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border: 1px solid #ddd;
    color: white;
  }
  
  .contactget-table-row:nth-child(even) {
    background-color: #fff;
  }
  
  .contactget-table-row:hover {
    background-color: #f1f1f1;
  }
  
  .contactget-table-data {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  /* No data row styles */
  .contactget-no-data-row {
    background-color: #ffe0e0;
    text-align: center;
  }
  
  .contactget-no-data {
    font-size: 16px;
    font-weight: bold;
    color: #777;
    padding: 10px;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .contactget-table-header,
    .contactget-table-data {
      font-size: 14px;
      padding: 6px;
    }
  }
  
  /* Scrollbar Styling */
  .contactget-table-wrapper {
    max-height: 90vh; /* Adjust height as needed */
    overflow-y: auto;
  }
  
  .contactget-table-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .contactget-table-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .contactget-table-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .contactget-table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .contactget-delete-button {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .contactget-delete-button:hover {
    background-color: #e60000;
    
  }
  