@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
}
.footer {
    position: relative;
    margin-top: 5%;
    height: auto;
    width: 100%;
    background: #22b1df;
    min-height: 100px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer-container {
    margin: 20px;
}

.social-icon,
.menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
    list-style: none;
}

.social-icon__link {
    font-size: 2rem;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

.social-icon__link:hover {
    transform: translateY(-10px);
}

.menu__link {
    font-size: 1.2rem;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
    opacity: 0.75;
    font-weight: 300;
}

.menu__link:hover {
    opacity: 1;
}

.footer p {
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
}

.wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../images/wave.png);
    background-size: 1000px 100px;
}

.wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate 4s linear infinite !important;
}

.wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
}

.footerring {
    width: 100%;
}

.footer-ptag {
    width: 240px;
}

.Footer-IMG1{
height: 30px;
}
.Footer-IMG2{
height: 30px;
margin-left: 5px;
}
.to-adjust_btn {
    margin-bottom: 5px;
    margin-right: 10px;
}

.fix_thePostion {
    display: flex;
    justify-content: start;
    align-items: center;
}


.fix-the_lines {
    margin-top: 10px;
    margin-right: 29px;
}

.div-for_text_and_btn {
    margin-left: 3%;
    width: 480px;
    border-radius: 100px;
    background-color: #51c2e5;
    padding: 20px;
}

.conateae {
    text-align: center;
}

.p-col-container {
    text-align: center;
}

.prox {
    margin-top: 25px;
    width: 100%;
}

                .handle-input-email {
                    padding: 10px;
                    border-radius: 30px;
                    margin-left: 5px;
                }

/* Base styles for both columns */
.footer-width1, .footer-width2 {
    padding: 0; /* Remove padding inside the columns */
    margin: 0; /* Remove any default margins */
  }
  
  /* Ensure "Contact Us" button and paragraph are closer */
  .footer-width1 .to-adjust_btn {
    margin-bottom: 10px; /* Adjust the space between the button and the next element */
  }
  
  .footer-width2 .footer-ptag {
    margin-top: 5px; /* Ensure paragraphs are closer to each other */
    margin-bottom: 5px; /* Reduce spacing between paragraph and button */
  }
  
  /* Adjust for larger screens between 1400px and 2600px */
  @media (min-width: 1400px) and (max-width: 3200px) {
    .footer-width1, .footer-width2 {
      flex: 0 0 20%; /* Columns take up roughly half the space */
      padding-right: 10px; /* Small padding between the columns */
    }
  
    .footer-width1 .to-adjust_btn {
    width: 160px;
      margin-bottom: 5px; /* Reduce space on larger screens */
    }
  
    .footer-width2 .footer-ptag {
      margin-top: 3px; /* Adjust spacing for larger screens */
      margin-bottom: 3px;
    }
  }
  
  /* For even larger screens (above 2600px) */
  @media (min-width: 3200px) {
    .footer-width1, .footer-width2 {
      flex: 0 0 49%; /* Adjust width slightly for large screens */
      padding-right: 5px; /* Smaller padding between the columns */
    }
  
    .footer-width1 .to-adjust_btn {
      margin-bottom: 3px; /* Ensure minimal spacing on large screens */
    }
  
    .footer-width2 .footer-ptag {
      margin-top: 2px; /* Minimal space between paragraphs */
      margin-bottom: 2px;
    }
  }
  
  

@keyframes animateWaves {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-position-x: 0px;
    }
}

@keyframes animate {
    0% {
        background-position-x: -1000px;
    }

    100% {
        background-position-x: 0px;
    }
}

@media screen and (max-width:1024) {
    .footerring {
        width: 100%;
    }

    .prox {
        margin-left: 0px;
    }

    .div-for_text_and_btn {
        padding: 15px;
    }
}


@media screen and (max-width: 768px) {
    .footerring {
        width: 100%;
    }

    .riding .col {
        padding-left: 3px;
        padding-right: 3px;
    }

    .fix_thePostion {
        margin-left: -5px;
    }

    .fix-the_lines {
        margin-right: 20px;
    }

    .to-adjust_btn {
        margin-bottom: 3px;
    }

    .div-for_text_and_btn {
        padding: 10px;
    }

    .conateae input[type="submit"] {
        margin-top: 10px;
    }

    .close-cols {
        margin-right: -16%;
    }

    .p-col-container {
        margin-left: 0%;
    }
}


@media screen and (max-width:580px) {
    .fix_thePostion {
        margin-left: 0px;
    }

.div-for_text_and_btn {
    margin-left: -5%;
    width: 405px;
}
}


@media screen and (max-width:475px) {
    .close-cols {
        margin-right: 5%;
        display: block;
        margin-left: 20px;
    }

    .footer-width {
        width: 100%;
    }

    .conateae input[type="email"] {
        margin-bottom: 10px;
    }

    .fix_thePostion {
        margin-left: 0px;
    }

    .to-adjust_btn {
        margin-left: 20px;
    }

    .div-for_text_and_btn {
        width: 18rem;
    }

    .p-col-container {
        margin-left: 10%;
    }
}

@media screen and (max-width:425px) {
    .footer {
        padding: 0px 9px;
    }

    .riding .col {
        width: 240px;
    }

    .fix-the_lines {
        margin-right: 10px;
    }

    .to-adjust_btn {
        margin-left: 15px;
    }

    .footerring {
        text-align: center;
    }

    .div-for_text_and_btn {
        width: 20rem;
        padding: 5px;
        text-align: center;
    }

    .close-cols {
        text-align: -webkit-center;
    }

}


/* Extra Small devices (phones, 375px and below) */
@media screen and (max-width: 375px) {
    .riding .col p {
        width: 10rem;
    }

    .div-for_text_and_btn {
        width: 16rem;
        margin-left: 0%;
    }
    .div-for_text_and_btn,label {
        font-size: small;
    }

}

/* Very Small devices (phones, 320px and below) */
@media screen and (max-width: 320px) {
    .footer {
        padding: 0px 5px;
    }
 
    .fix-the_lines {
        margin-right: 5px;
    }

    .to-adjust_btn {
        margin-bottom: 1px;
    }
}

.handle-input-email {
            width: 200px;
        }
@media screen and (max-width:295px) {
    .div-for_text_and_btn {
        margin-left: 6%;
        width: 90%;
    }
    
    .div-for_text_and_btn{
        font-size: 12px;
    }

    .social-media-icons {
        margin-left: 15px;
    }

    .social-media-subscribeBtn{
        margin-left: 10px;  
    }

    .prox {
        width: 95%;
    }

    .close-cols {
        margin-left: -10px;
    }

    .handle-input-email {
        width: 175px;
        padding: 5px;
    }

 form .btn-subscribe{
    padding: 10px;
}

form .handle-input-email{
    padding: 7px;

}

.p-col-container {
    margin-left: 5%;
}
}