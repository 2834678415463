/* Login.css */

  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    /* background-color: #f0f2f5; */
  }
  
  .login-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8rem;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
    border-bottom: 2px solid rgb(34, 177, 223);
    border-radius: 5px;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .input-field:focus {
    /* border-color: #007bff; */
    outline: none;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #22B1DF;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  @keyframes slideIn {
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.login-form {
    animation: slideIn 0.5s ease forwards; /* Apply the animation */
}

.password-container {
  width: 350px;
  position: relative;
  display: flex;
  align-items: center;
}

.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #666;
};