.banner-container{
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

.input-container {
    padding: 10px;
}

input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: box-shadow 0.3s ease;
}

.bannerimage-1{
    position: relative;
    bottom: 0;
    right: 0;
    margin-top: 50px;
}

input[type="text"]:focus {
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.banner-heading1{
    color: #22B1DF;
    margin-bottom: 80px;
    -webkit-text-stroke: 1px white;
    font-weight: bold;
}

.button-container {
    text-align: center;
}
.btn {
    display: inline-block;
    padding: 15px 30px;
    background-color: #22B1DF;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.btn:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

.btn:active {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
}

/* Freehydration component css file */

.freehyration-heading1{
    color: #22B1DF;
    text-align: justify;
    font-weight: 300;
    word-spacing: 3px;
    letter-spacing: 1px;
    line-height: 40px;
}