/* Legends.css */


  .input-container {
    padding: 10px;
}

#myinput::placeholder {
    color: #22B1DF;
    opacity: .5;
    /* Firefox */
}

#myinput::-ms-input-placeholder {
    color: #22B1DF;
    opacity: .5;
}


.button-container {
    text-align: center;
}

/* .btn {
    display: inline-block;
    padding: 15px 30px;
    background-color: #22B1DF;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.btn:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

.btn:active {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
} */

.model-container {
    padding: 10px;
    /* background:#efefef; */
    width: 100%;
    height: 800px;
    overflow: hidden;
    perspective: 1600px;
}

.model {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
}

.model img {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}