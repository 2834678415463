@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.bg-water {
    background-color: #22B1DF;
}

.navbar-nav .nav-item {
    padding-left: 20px;
}

.navbar-nav .nav-item a {
    color: #ffffff;
    padding: 10px;
}

h4 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

h5 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}
#banner{
    background-image: url(../images/background_banner.png);
}
.menu-item:hover {
    color: #18d4bc;
    transition: all 0.3s ease 0s;
    font-weight: 400;
    cursor: pointer;
}

#mybutton {
    text-decoration-color: #18d4bc;
    border: 1px solid #18d4bc;
    padding: 10px 50px;
    border-radius: none;
    background: none;
    transition: all 0.3s ease 0s;
}

#mybutton:hover {
    background-color: #18d4bc;
    color: white;
}

#mybutton2 {
    border: 1px solid #ffffff;
    padding: 10px 50px;
    border-radius: none;
    background: none;
    transition: all 0.3s ease 0s;
}

#mybutton2:hover {
    background-color: #18d4bc;
    color: white;
}

.myinput {
    border: 1px solid #18d4bc;
    padding: 10px 50px;
    border-radius: none;
    background: none;
    transition: all 0.3s ease 0s;
}

#bottomMenu {
    display: flex;
    justify-content: space-around;
    color: white
}

#bottomMenu a {
    color: white;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    font-weight: 500;
}


  .menu{
    display: none;
  }
  .menu.show {
    display: none;
  }
  .menu.close{
    display: none;
  }

/* Reduce font size and padding for navbar links on smaller screens */
@media screen and (max-width: 1110px) {
  .navbar-nav .nav-item a {
    font-size: 14px;
    /* Adjust as needed */
    padding: 8px;
    /* Reduce padding for narrower fit */
    white-space: nowrap;
    /* Prevent text wrapping */
  }

  /* Adjust the layout of the navbar items for a tighter fit */
  .navbar-nav .nav-item {
    padding-left: 10px;
    /* Decrease spacing between items */
  }
}

/* Ensure menu toggle button is visible and responsive */
.navbar-toggler {
  border: none;
  padding: 0.25rem 0.75rem;
  font-size: 18px;
}
  @media screen and (max-width:990px) {
    .hosting{
        display: none;
    }

    .menu{
      display: block;
  }
  .menu.show {
      display: block;
    }
  }
 
  /* Change link color when clicked (active state) */

  /* Change the color of active link */
  .nav-link.activeing {
    color: #ff6b6b; /* Change this to the desired active color */
    font-weight: bold; /* Optional: make the active link bold */
    background-color: #51c2e5;
    border-radius: 20px;
  }
    

  /* newheader css */
  .header-container{
background-color: #51c2e5;
padding: 4px 40px 4px 0px;
  }       
  
  .IMG1{
height: 20px;
  }
  .IMG2{
height: 20px; margin-left: 5px;
  }