model-viewer.input-container {
    padding: 10px;
}

#myinput::placeholder {
    color: #22B1DF;
    opacity: .5;
    /* Firefox */
}

#myinput::-ms-input-placeholder {
    color: #22B1DF;
    opacity: .5;
}


.button-container {
    text-align: center;
}

.btn {
    display: inline-block;
    padding: 15px 30px;
    background-color: #22B1DF;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.btn:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

.btn:active {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
}

.model-container {
    padding: 10px;
    /* background:#efefef; */
    height: 00px; /* Adjust the height as per your requirement */
    width: 100%;  
    overflow: hidden;
    perspective: 1600px;
}
model-viewer{
    height: 15pc; /* Ensure model-viewer takes full height of the container */
    width: 100%;
}

.model {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
}

.model img {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}
/* Spotlightwall.css */
.scrollable-circle {
    width: 300px; /* Adjust the width and height as needed */
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto; /* Center the circle */
    border: 3px solid #22B1DF;
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scrollable-image {
    width: 600px; /* Make this larger than the container to enable scrolling */
    height: auto;
    cursor: grabbing;
    pointer-events: none;
  }
  
  .scrollable-circle:active {
    cursor: grabbing;
  }

  .product {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    /* width: 265px; */
    transition: 0.7s;
    margin-bottom: 20px;
  }
  
  .soda {
    --left: 0px;
    background: var(--front-url) var(--left), url(../images/images/mockup.png) 0 0;
    width: 240px;
    max-height: 500px;
    aspect-ratio: 2 / 4;
    background-blend-mode: multiply;
    mask-image: url(../images/images/mockup.png);
    mask-size: auto 100%;
    background-size: auto 100%;  /* Ensures background size consistency */
    background-position: center;
    transition: 2s ease-in-out, background 2 ease-in-out; /* Smooth transition */
    transform: rotateY(0deg);
      /* Default view is front */
  }
  
    .product:hover .soda {
      background: var(--back-url) var(--left), url(../images/images/mockup.png) 0 0;
      background-size: auto 100%;
      transform: rotateY(0deg);
        /* Rotate to show back side */
      /* Prevents zooming during hover */
    }

  .sidi {
    margin-left: 30px;
    background-size: auto 100%;
    background-position: center;
    position: relative;
    transition: 2s ease-in-out;
  }
  
  .can-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    background-color: transparent;
    position: relative;
  }
  
  /* Style the actual 3D canvas (for the can) */
  .can-container canvas {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain; /* Make sure the canvas fits within the container */
  }
  
  .text-data {
    margin-top: 10px;
    font-size: 0.9em;
    color: #333;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
  }
  
  .product:hover .soda {
    opacity: 1;
    --left: 500px;
  }
  
  
  .contactget-delete-button {
    position: relative; 
    z-index: 10;
  }

  /* Container Styling */
.spotlight-container {
  margin-bottom: 30px;
}

.admin-container{
  margin-top: 100px;
}

.uploadcan-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  justify-content: center; /* Center items */
}

.uploadcan-container .can-container {
  width: 100%; /* Default full width for small screens */
  margin: 10px; /* Space between cans */
}

@media (min-width: 576px) {
  /* Small devices (portrait tablets and large phones, 576px and up) */
  .uploadcan-container .can-container {
    width: calc(50% - 20px); /* 2 cans per row */
  }
}

@media (min-width: 768px) {
  /* Medium devices (landscape tablets, 768px and up) */
  .uploadcan-container .can-container {
    width: calc(33.33% - 20px); /* 3 cans per row */
  }
}

@media (min-width: 992px) {
  /* Large devices (desktops, 992px and up) */
  .uploadcan-container .can-container {
    width: calc(25% - 20px); /* 4 cans per row */
  }
}


/* ================= */

/* Spotlightwall.css */

/* Spinner Container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px; /* Adjust as needed */
}

/* Spinner Style */
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #22B1DF; /* Blue color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* Spin Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spotlight-paragraph{
        text-align: justify;
        font-weight: 300;
        word-spacing: 3px;
        letter-spacing: 1px;
        line-height: 30px;
}