/* Container */
.distrubution-container {
    padding: 10px;
    margin-left: 200px; /* Adjust if needed */
    transition: margin-left 0.3s ease;
    -webkit-transition: margin 2s;
    transition: margin 2s;
  }
  
  .sidebar-open {
    margin-left: 17pc; /* Adjust if needed */
  }
  @media screen and (max-width:768px) {
 .sidebar-open {
        margin-left: 4pc; /* Adjust if needed */
      }
      .sidebar-close {
        margin-left: 4pc; /* Adjust if needed */
      }
  }
  .sidebar-close {
    margin-left: 4pc; /* Adjust if needed */
  }
  
  /* Heading */
  .distrubution-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Table Wrapper */
  .distrubution-table-wrapper {
    overflow-x: auto;
    margin: 0 auto;
  }
  
  /* Table */
  .distrubution-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .distrubution-table-header-row {
    background-color: #22B1DF;
  }
  
  .distrubution-table-header {
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border: 1px solid #ddd;
    color: white;
  }
  
  .distrubution-table-row:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .distrubution-table-row:hover {
    background-color: #f1f1f1;
  }
  
  .distrubution-table-data {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  /* No Data Row */
  .distrubution-no-data-row {
    text-align: center;
  }
  
  .distrubution-no-data {
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #777;
  }
  
  /* Responsive Table */
  @media screen and (max-width: 768px) {
    .distrubution-table-header,
    .distrubution-table-data {
      font-size: 14px;
      padding: 6px;
    }
  }
  
  /* Scrollbar Styling */
  .distrubution-table-wrapper {
    max-height: 90vh; /* Adjust height as needed */
    overflow-y: auto;
  }
  
  .distrubution-table-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .distrubution-table-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .distrubution-table-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .distrubution-table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  