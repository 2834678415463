.input-container {
  padding: 10px;
}

.input {
  border: 1px solid #28b4dc;
}

input[type="text"] {

  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.3s ease;
  border-bottom: 2px solid rgb(34, 177, 223);

}

input[type="text"]:focus {
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.2);
}

select,
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.3s ease;
}

select:focus {
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.button-container {
  text-align: center;
}

.btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #28b4dc;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.btn:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.btn:active {
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3);
  transform: translateY(2px);
}
@keyframes zoomInDown {
  0% {
    transform: scale(0.1) translateY(-2000px);
    opacity: 0;
  }
  50% {
    transform: scale(0.475) translateY(60px);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.zoom-in-down {
  animation: zoomInDown 1s ease-in-out forwards; /* Adding smooth animation */
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-20px); /* Start smaller and higher */
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0); /* End with normal size and position */
  }
}


/* Ensure the parent row behaves as a flex container */
.cardingg {
display: flex;
flex-wrap: wrap; /* Wrap items to handle responsive design */
}

.menin{
display: flex;
align-items: stretch; /* Make columns stretch to the same height */
}


