.term-condition-container{
    font-weight: 300;
            word-spacing: 3px;
            letter-spacing: 1px;
            line-height: 35px;
            margin-top: 80px;
            margin-bottom: 80px;
}

.term-condition-container h3{
    color: #22b1df;
}

.term-condition-container h2{
    font-weight: 600;
    font-size: 40px;
}

.spantag-li{
    color: darkcyan;
    font-weight: 600;
}
